import { useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { shape, func, arrayOf } from 'prop-types';
import classes from './DrugBlocks.module.scss';
import Block from './Block';

const DrugBlocksWithSelect = ({ blockOrderOptions = [], onChangeOrder = () => null }) => {
  const [blocks, setBlocks] = useState(blockOrderOptions);

  const moveBlock = (dragIndex, hoverIndex) => {
    const dragBlock = blocks[dragIndex];
    const updatedBlocks = [...blocks];
    updatedBlocks.splice(dragIndex, 1);
    updatedBlocks.splice(hoverIndex, 0, dragBlock);
    setBlocks(updatedBlocks);
    onChangeOrder(updatedBlocks.filter(item => item?.is_select).map(el => el?.value));
  };

  const selectBlock = (selectedIndex) => {
    const updatedBlocks = [...blocks];
    const updatedItem = updatedBlocks[selectedIndex];
    updatedItem.is_select = !updatedItem.is_select;
    updatedBlocks.splice(selectedIndex, 1, updatedItem);
    setBlocks(updatedBlocks);
    onChangeOrder(updatedBlocks.filter(item => item?.is_select).map(el => el?.value));
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className={classes.blocksWrap}>
        {blocks.map((block, index) => (
          <Block
            key={index}
            blockValue={block.value}
            blockLabel={block.label}
            isSelect={block.is_select}
            index={index}
            moveBlock={moveBlock}
            selectBLock={selectBlock}
          />
        ))}
      </div>
    </DndProvider>
  );
};

DrugBlocksWithSelect.propTypes = {
  blockOrderOptions: arrayOf(shape),
  onChangeOrder: func
};

export default DrugBlocksWithSelect;
