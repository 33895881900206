import { useMemo, useState } from 'react';
import { func, shape } from 'prop-types';
import t from 'utils/translateFunc';
import cn from 'classnames';
import { Icon, SelectCustom, RadioButtonGroup } from 'Components';
import { useGetTemplateMeta } from 'storage/queryHooks';
import { ColorPicker } from 'antd';
import colors from 'styles/_colors.scss';
import classes from '../TemplateDrawer.module.scss';
import { dispayOptions } from './options';
import DrugBlocks from './DrugBlocks';
import DrugBlocksWithSelect from './DrugBlocksWithSelect';

const stylesMulti = {
  control: (provided, state) => ({
    ...provided,
    width: '100%',
    minHeight: '32px',
    backgroundColor: state?.sort ? colors.fontBody : colors.white,
    borderRadius: '4px',
    boxShadow: colors.boxShadow,
    border: 'none',
    outline: 'none',
    opacity: state.isDisabled ? '0.8' : '1'
  }),
  valueContainer: provided => ({
    ...provided,
    padding: '6px',
    paddingLeft: '12px'
  }),

  multiValue: provided => ({
    ...provided,
    margin: 0,
    padding: '4px 0',
    backgroundColor: colors.white
  }),
  multiValueLabel: provided => ({
    ...provided,
    padding: '0 2px',
    paddingLeft: '2px',
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: '400',
    color: colors.textPrimary,
    cursor: 'pointer',
    transition: 'all 150ms linear',
    '&:hover + div[role="button"]': {
      opacity: 1
    }
  }),
  multiValueRemove: provided => ({
    ...provided,
    padding: 0,
    cursor: 'pointer',
    opacity: 0,
    backgroundColor: colors.white,
    color: colors.font50,
    transition: 'all 150ms linear',
    '&:hover': {
      opacity: 1,
      backgroundColor: colors.white,
      color: colors.font50
    }
  })
};

function DisplayComponent({ data = null, onChange = () => null }) {
  const [showXLSFieldsChoices, setShowXLSFieldsChoices] = useState(false);
  const { data: metaTemplateData, isFetched: isMetaFetched } = useGetTemplateMeta({ enabled: true });

  const { textDisplayTypes, articlesGroupBy, articlesOrderBy, fontScales, articlesFields, articlesDirections, articlesFilterDate,
    chartsSizes, repeatingArticlesDisplays, blocksOrders } = useMemo(() => dispayOptions(), []);

  const textDisplayType = data?.text_display_type ? { value: data.text_display_type, label: textDisplayTypes[data.text_display_type] } : null;
  const articleGroupBy = data?.articles_group_by ? { value: data.articles_group_by, label: articlesGroupBy[data.articles_group_by] } : null;
  const repeatingArticlesDisplay = data?.repeating_articles_display
    ? { value: data.repeating_articles_display, label: repeatingArticlesDisplays[data.repeating_articles_display] } : null;

  const articleOrderBy = data?.articles_order_by ? { value: data.articles_order_by, label: articlesOrderBy[data.articles_order_by] } : null;
  const articleField = data?.article_fields?.length ? data?.article_fields?.map(field => ({ value: field, label: articlesFields[field] })) : null;
  const fontScale = data?.font_scale ? { value: data.font_scale, label: fontScales[data.font_scale] } : null;

  const textDisplayTypesOptions = useMemo(() => Object.entries(textDisplayTypes).map(([value, label]) => ({ value, label })), []);
  const articlesGroupByOptions = useMemo(() => Object.entries(articlesGroupBy).map(([value, label]) => ({ value, label })), []);
  const articlesOrderByOptions = useMemo(() => Object.entries(articlesOrderBy).map(([value, label]) => ({ value, label })), []);
  const articlesFieldsOptions = useMemo(() => Object.entries(articlesFields).map(([value, label]) => ({ value, label })), []);
  const articlesDirectionsOptions = useMemo(() => Object.entries(articlesDirections).map(([value, label]) => ({ value, label })), []);
  const articlesFilterDateOptions = useMemo(() => Object.entries(articlesFilterDate).map(([value, label]) => ({ value, label })), []);
  const chartsSizesOptions = useMemo(() => Object.entries(chartsSizes).map(([value, label]) => ({ value, label })), []);
  const fontScalesOptions = useMemo(() => Object.entries(fontScales).map(([value, label]) => ({ value, label })), []);
  const repeatingArticlesDisplaysOptions = useMemo(() => Object.entries(repeatingArticlesDisplays).map(([value, label]) => ({ value, label })), []);
  const blockOrderOptions = useMemo(() => (data?.blocks_order?.length
    ? data?.blocks_order?.map(block => ({ value: block, label: blocksOrders[block] })) : null), [data?.blocks_order]);

  const xlsFieldsChoices = useMemo(() => {
    if (data?.xls_fields?.length && metaTemplateData?.xls_fields_choices) {
      const selected_columns = data.xls_fields.map(item => ({ value: item, label: metaTemplateData.xls_fields_choices[item], is_select: true }));
      const not_selected_columns = Object.entries(metaTemplateData.xls_fields_choices).map(([value, label]) => ({ value, label, is_select: false })).filter(item => !data.xls_fields.includes(item.value)) || [];
      return [...selected_columns, ...not_selected_columns];
    }
    if (metaTemplateData?.xls_fields_choices) {
      return Object.entries(metaTemplateData.xls_fields_choices).map(([value, label]) => ({ value, label, is_select: true }));
    }
    return null;
  }, [metaTemplateData, metaTemplateData?.xls_fields_choices, data?.xls_fields, data?.xls_fields?.length]);

  return (
    <div className={classes.displayComponent}>
      <div className={classes.switcherWrap}>
        <label className={cn(classes.switch)}>
          <input
            type="checkbox"
            onChange={() => { onChange({ separate_socialnetwork: !data?.separate_socialnetwork }); }}
            checked={!!data?.separate_socialnetwork}
          />
          <span className={cn(classes.slider, classes.round)} />
        </label>
        <span className={classes.switcherLabel}>
          {t('reports', 'SEPARATE_MEDIA_AND_SOCIAL')}
        </span>
      </div>
      <div className={classes.switcherCommonWrap}>
        <div className={classes.switcherWrap}>
          <label className={cn(classes.switch)}>
            <input
              type="checkbox"
              onChange={() => { onChange({ show_contents: !data?.show_contents }); }}
              checked={!!data?.show_contents}
            />
            <span className={cn(classes.slider, classes.round)} />
          </label>
          <span className={classes.switcherLabel}>
            {t('reports', 'SHOW_ARTICLE_CONTENT')}
          </span>
        </div>
        { !!data?.show_contents && (
          <div className={classes.checkboxWrap}>
            <label className={classes.checkboxLabel}>
              <input
                className={classes.visually_hidden}
                type="checkbox"
                checked={!!data?.show_contents_tone}
                onChange={() => { onChange({ show_contents_tone: !data?.show_contents_tone }); }}
              />
              <div className={cn(classes.checkbox, !!data?.show_contents_tone && classes.checkbox_selected)}>
                {!!data?.show_contents_tone && (<Icon type="checked" className={classes.iconCheck} />)}
              </div>
              {t('reports', 'SHOW_TONE')}
            </label>
          </div>
        )}
        { !!data?.show_contents && (
          <div className={classes.checkboxWrap}>
            <label className={classes.checkboxLabel}>
              <input
                className={classes.visually_hidden}
                type="checkbox"
                checked={!!data?.show_contents_source}
                onChange={() => { onChange({ show_contents_source: !data?.show_contents_source }); }}
              />
              <div className={cn(classes.checkbox, !!data?.show_contents_source && classes.checkbox_selected)}>
                {!!data?.show_contents_source && (<Icon type="checked" className={classes.iconCheck} />)}
              </div>
              {t('reports', 'SHOW_SOURCE_TITLE')}
            </label>
          </div>
        ) }
      </div>
      <div className={classes.switcherCommonWrap}>
        <div className={classes.switcherWrap}>
          <label className={cn(classes.switch)}>
            <input
              type="checkbox"
              onChange={() => { onChange({ show_duplicates: !data?.show_duplicates }); }}
              checked={!!data?.show_duplicates}
            />
            <span className={cn(classes.slider, classes.round)} />
          </label>
          <span className={classes.switcherLabel}>
            {t('reports', 'SHOW_DUPLICATES')}
          </span>
        </div>
        { !!data?.show_duplicates && (
          <div className={classes.checkboxWrap}>
            <label className={classes.checkboxLabel}>
              <input
                className={classes.visually_hidden}
                type="checkbox"
                checked={!!data?.group_under_original}
                onChange={() => { onChange({ group_under_original: !data?.group_under_original }); }}
              />
              <div className={cn(classes.checkbox, !!data?.group_under_original && classes.checkbox_selected)}>
                {!!data?.group_under_original && (<Icon type="checked" className={classes.iconCheck} />)}
              </div>
              {t('reports', 'ORIGINAL_GROUP')}
            </label>
          </div>
        ) }
      </div>
      {textDisplayTypesOptions && (
      <div className={classes.selectWrapper}>
        <div className={classes.seletMenuTitle}>{t('reports', 'TEXT_DISPLAY_MODE')}</div>
        <SelectCustom
          value={textDisplayType}
          placeholder={t('reports', 'SELECT_TEXT_DISPLAY_MODE')}
          onChange={({ value }) => { onChange({ text_display_type: value }); }}
          options={textDisplayTypesOptions}
        />
      </div>
      )}
      {articlesGroupByOptions && (
      <div className={classes.selectWrapper}>
        <div className={classes.seletMenuTitle}>{t('reports', 'MESSAGE_GROUPING')}</div>
        <SelectCustom
          value={articleGroupBy}
          placeholder={t('reports', 'MESSAGE_GROUPING_TYPE')}
          onChange={({ value }) => { onChange({ articles_group_by: value }); }}
          options={articlesGroupByOptions}
        />
      </div>
      )}
      {repeatingArticlesDisplaysOptions && (
      <div className={classes.selectWrapper}>
        <div className={classes.seletMenuTitle}>{t('reports', 'IF_NEWS_REPEATS')}</div>
        <SelectCustom
          value={repeatingArticlesDisplay}
          placeholder={t('reports', 'TYPE_NEWS_REPEATS')}
          onChange={({ value }) => { onChange({ repeating_articles_display: value }); }}
          options={repeatingArticlesDisplaysOptions}
        />
      </div>
      )}
      {!!articlesOrderByOptions && (
      <div className={classes.selectWrapper}>
        <div className={classes.seletMenuTitle}>{t('reports', 'SORT_GROUPED_MESSAGES')}</div>
        <SelectCustom
          value={articleOrderBy}
          placeholder={t('reports', 'TYPE_SORTING_GROUPED_MESSAGES')}
          onChange={({ value }) => { onChange({ articles_order_by: value }); }}
          options={articlesOrderByOptions}
        />
      </div>
      )}
      <div className={classes.selectWrapper}>
        <div
          className={cn(classes.seletMenuTitle, classes.seletMenuTitle_is_btn)}
          onClick={() => setShowXLSFieldsChoices(!showXLSFieldsChoices)}
        >
          <div className={cn(classes.btn_icon, !showXLSFieldsChoices && classes.btn_icon_is_close)}>
            <Icon type="arrow_down" color={colors.font70} />
          </div>
          <span>{t('reports', 'XLS_FIELDS')}</span>
        </div>
        {showXLSFieldsChoices && isMetaFetched && (
          <div className={classes.fieldsChoices}>
            {data?.id && metaTemplateData?.xls_fields_choices && (
              <DrugBlocksWithSelect
                blockOrderOptions={xlsFieldsChoices}
                onChangeOrder={(xls_fields_choices) => {
                  onChange({ xls_fields: xls_fields_choices });
                }}
              />
            )}
            {!data?.id && metaTemplateData?.xls_fields_choices && (
              <DrugBlocksWithSelect
                blockOrderOptions={Object.entries(metaTemplateData.xls_fields_choices).map(([value, label]) => ({ value, label, is_select: true }))}
                onChangeOrder={(xls_fields_choices) => {
                  onChange({ xls_fields: xls_fields_choices });
                }}
              />
            )}
          </div>
        )}
      </div>
      <RadioButtonGroup
        className={classes.radioGroup}
        groupName={t('reports', 'SORT_DIRECTION')}
        options={articlesDirectionsOptions}
        defaultValue={data?.articles_order_by_desc ? 'articles_order_by_asc' : 'articles_order_by_desc'}
        onChange={(e) => {
          onChange({ articles_order_by_desc: e.target.value !== 'articles_order_by_desc' });
        }}
      />
      <RadioButtonGroup
        className={classes.radioGroup}
        groupName={t('reports', 'FILTER_ARTICLES_AUTOREPORTS')}
        options={articlesFilterDateOptions}
        defaultValue={data?.by_created_date ? 'by_production_date' : 'by_created_date'}
        onChange={(e) => {
          onChange({ by_created_date: e.target.value !== 'by_created_date' });
        }}
      />
      {!!articlesFieldsOptions && (
      <div className={classes.selectWrapper}>
        <div className={classes.seletMenuTitle}>{t('reports', 'ARTICLE_INFO')}</div>
        <SelectCustom
          value={articleField}
          placeholder={t('reports', 'SELECT_ARTICLE_INFO')}
          onChange={(fields) => { onChange({ article_fields: fields.map(({ value }) => value) }); }}
          options={articlesFieldsOptions}
          isMulti
          isClearable
          closeMenuOnSelect={false}
          styles={stylesMulti}
        />
      </div>
      )}

      <RadioButtonGroup
        className={classes.radioGroup}
        groupName={t('reports', 'CHART_SIZE')}
        options={chartsSizesOptions}
        defaultValue={data?.charts_small ? 'charts_large' : 'charts_small'}
        onChange={(e) => {
          onChange({ charts_small: e.target.value !== 'charts_small' });
        }}
      />
      {!!fontScalesOptions && (
      <div className={classes.selectWrapper}>
        <div className={classes.seletMenuTitle}>{t('reports', 'FONT_SIZE')}</div>
        <SelectCustom
          value={fontScale}
          placeholder={t('reports', 'SELECT_FONT_SIZE')}
          onChange={({ value }) => { onChange({ font_scale: value }); }}
          options={fontScalesOptions}
        />
      </div>
      )}
      <div className={classes.switcherCommonWrap}>
        <div className={classes.switcherWrap}>
          <label className={cn(classes.switch)}>
            <input
              type="checkbox"
              onChange={() => { onChange({ highlight_keywords: !data?.highlight_keywords }); }}
              checked={!!data?.highlight_keywords}
            />
            <span className={cn(classes.slider, classes.round)} />
          </label>
          <span className={classes.switcherLabel}>
            {t('reports', 'HIGHLIGHT_KEYWORDS')}
          </span>
        </div>
        { !!data?.highlight_keywords && (
          <div className={classes.checkboxWrap}>
            <label className={classes.checkboxLabel}>
              {t('reports', 'HIGHLIGHT_COLOR')}
              <ColorPicker
                rootClassName={classes.colorPickerTemplate}
                styles={{ popupOverlayInner: { padding: '5px' } }}
                destroyTooltipOnHide
                format="hex"
                disabledAlpha
                defaultFormat="hex"
                onChangeComplete={(color) => { onChange({ keywords_highlight_color: color.toHex() }); }}
                size="small"
                value={`#${data?.keywords_highlight_color}`}
                defaultValue={colors.purple600}
              />
            </label>
          </div>
        )}
      </div>
      {blockOrderOptions?.length && (
        <div className={classes.selectWrapper}>
          <div className={classes.seletMenuTitle}>{t('reports', 'BLOCK_ORDER')}</div>
          <DrugBlocks
            blockOrderOptions={blockOrderOptions}
            onChangeOrder={(blocks_order) => { onChange({ blocks_order }); }}
          />
        </div>
      )}
    </div>
  );
}

DisplayComponent.propTypes = {
  onChange: func,
  data: shape({})

};

export default DisplayComponent;
