import { string, number } from 'prop-types';
import colors from 'styles/_colors.scss';

export function PlayButtonBig({ width = 36, height = 36, color = colors.white, className = '' }) {
  return (
    <svg className={className} width={width || '36'} height={height || '36'} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="36" height="36" rx="18" fill="white" fillOpacity="0.3" />
      <path
        d="M15.5 22.5L22.5 18L15.5 13.5V22.5ZM18 28C16.6167 28 15.3167 27.7375 14.1 27.2125C12.8833 26.6875 11.825 25.975 10.925 25.075C10.025 24.175 9.3125 23.1167 8.7875 21.9C8.2625 20.6833 8 19.3833 8 18C8 16.6167 8.2625 15.3167 8.7875 14.1C9.3125 12.8833 10.025 11.825 10.925 10.925C11.825 10.025 12.8833 9.3125 14.1 8.7875C15.3167 8.2625 16.6167 8 18 8C19.3833 8 20.6833 8.2625 21.9 8.7875C23.1167 9.3125 24.175 10.025 25.075 10.925C25.975 11.825 26.6875 12.8833 27.2125 14.1C27.7375 15.3167 28 16.6167 28 18C28 19.3833 27.7375 20.6833 27.2125 21.9C26.6875 23.1167 25.975 24.175 25.075 25.075C24.175 25.975 23.1167 26.6875 21.9 27.2125C20.6833 27.7375 19.3833 28 18 28ZM18 26C20.2333 26 22.125 25.225 23.675 23.675C25.225 22.125 26 20.2333 26 18C26 15.7667 25.225 13.875 23.675 12.325C22.125 10.775 20.2333 10 18 10C15.7667 10 13.875 10.775 12.325 12.325C10.775 13.875 10 15.7667 10 18C10 20.2333 10.775 22.125 12.325 23.675C13.875 25.225 15.7667 26 18 26Z"
        fill={color || colors.white}
      />
    </svg>
  );
}

PlayButtonBig.propTypes = {
  width: number,
  height: number,
  color: string,
  className: string
};
