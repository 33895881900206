import { useMemo, memo } from 'react';
import { useDispatch } from 'react-redux';
import cn from 'classnames';
import { string, func, shape } from 'prop-types';
import { sendFeedEvent } from 'services/amplitude/events';
import { openModal } from 'storage/actions';
import { Icon, TonePopover, Tooltip } from 'Components';
import t from 'utils/translateFunc';
import { roundFnString } from 'utils/common';
import { isShowMetric } from 'data/metricsConfig';
import like from 'assets/png/reactions/like_small.png';
import love from 'assets/png/reactions/love_small.png';
import funny from 'assets/png/reactions/funny_small.png';
import support from 'assets/png/reactions/support_small.png';
import wow from 'assets/png/reactions/wow_small.png';
import sad from 'assets/png/reactions/sad_small.png';
import angry from 'assets/png/reactions/angry_small.png';
import colors from 'styles/_colors.scss';
import classes from './IconsListMedia.module.scss';

const reactions = { like, love, funny, support, wow, sad, angry };

const IconsListMedia = memo(({ language = 'uk', info = null, onSubmit = () => null }) => {
  const dispatch = useDispatch();
  const engagements = [
    { image: 'like', count: info?.reaction_like_count || 0 },
    { image: 'love', count: info?.reaction_love_count || 0 },
    { image: 'funny', count: info?.reaction_haha_count || 0 },
    { image: 'support', count: info?.reaction_support_count || 0 },
    { image: 'wow', count: info?.reaction_wow_count || 0 },
    { image: 'sad', count: info?.reaction_sorry_count || 0 },
    { image: 'angry', count: info?.reaction_anger_count || 0 }
  ];
  const topEngagments = [...engagements].filter(item => !!item?.count)?.sort((a, b) => (a.count > b.count ? -1 : 1)).slice(0, 3);

  function openTree(types = 'comments') {
    if (types === 'comments' && info?.post_type === 'post' && info?.comment_count === 0) return;
    if (types === 'reposts' && info?.post_type === 'post' && info?.repost_count === 0) return;

    if (types === 'comments') sendFeedEvent({ category: 'Feed_Article_Card', event: 'Clicked_Comments_Button' });
    if (types === 'reposts') sendFeedEvent({ category: 'Feed_Article_Card', event: 'Clicked_Reposts_Button' });

    const source_name = info?.source?.name || info?.source || '';
    const source_url = info?.socialnetworkarticle?.url || info?.forumarticle?.url || info?.blogarticle?.url || info?.url || '';

    const author_name = info?.creators?.length ? info?.creators[0]?.name : '';
    const author_url = source_url || info?.url || '';

    const publication_place_name = info?.publication_place_name || info?.publication_place?.name || '';
    const publication_place_url = info?.publication_place?.url || info?.url || '';

    dispatch(openModal({
      action: 'descendantsTree',
      info: {
        id: info?.id,
        title: info?.title || '',
        source_name: info?.source?.name || info?.source || '',
        media_type: info?.media_type,
        post_type: info?.post_type,
        article_id: info?.looqme_sn_id,
        date: info?.date,
        like_count: info?.like_count || 0,
        comment_count: info?.comment_count || 0,
        repost_count: info?.repost_count || 0,
        views_count: info?.views_count || 0,
        duplicates_count: info?.duplicates_count || 0,
        potential_reach: info?.potential_reach || 0,
        source: { name: source_name, url: source_url },
        author: { name: author_name, url: author_url },
        publication_place: { name: publication_place_name, url: publication_place_url },
        types
      }
    }));
  }

  const iconsList = useMemo(() => {
    if (info?.media_type === 'socialnetwork') {
      return [
        { type: 'likes', role: 'button', tooltipText: t('tooltip', 'LIKES', language), text: `${roundFnString(info?.like_count) || 0}`, handleClick: () => null },
        { type: 'comments', role: 'button', tooltipText: t('tooltip', 'COMMENTS', language), text: `${roundFnString(info?.comment_count) || 0}`, handleClick: () => openTree('comments') },
        isShowMetric(info, 'reposts') && { type: 'reposts', role: 'button', tooltipText: t('tooltip', 'REPOSTS', language), text: `${roundFnString(info?.repost_count) || 0}`, handleClick: () => openTree('reposts') }
      ];
    }
    if (info?.media_type === 'review') {
      return [
        { type: 'likes', role: 'button', tooltipText: t('tooltip', 'LIKES', language), text: `${roundFnString(info?.like_count) || 0}` },
        { type: 'comments', role: 'button', tooltipText: t('tooltip', 'COMMENTS', language), text: `${roundFnString(info?.comment_count) || 0}` }
      ];
    }
    if (info?.media_type === 'blog') {
      return [
        { type: 'likes', role: 'button', tooltipText: t('tooltip', 'LIKES', language), text: `${roundFnString(info?.like_count) || 0}` },
        { type: 'comments', role: 'button', tooltipText: t('tooltip', 'COMMENTS', language), text: `${roundFnString(info?.comment_count) || 0}` }
      ];
    }
    if (info?.media_type === 'forum') {
      return [
        { type: 'likes', role: 'button', tooltipText: t('tooltip', 'LIKES', language), text: `${roundFnString(info?.like_count) || 0}` },
        { type: 'comments', role: 'button', tooltipText: t('tooltip', 'COMMENTS', language), text: `${roundFnString(info?.comment_count) || 0}` }
      ];
    }
    if (info?.media_type === 'tv') {
      return [
        { type: 'audience', role: 'button', tooltipText: t('tooltip', 'AUDIENCE', language), text: `${roundFnString(info?.average_attendance) || info?.source?.average_attendance || 0}` },
        { type: 'ave', role: 'button', tooltipText: 'AVE', text: `${roundFnString(info?.ave) || 0}` }
      ];
    }
    if (info?.media_type === 'tv') {
      return [
        { type: 'audience', role: 'button', tooltipText: t('tooltip', 'AUDIENCE', language), text: `${roundFnString(info?.average_attendance) || info?.source?.average_attendance || 0}` },
        { type: 'ave', role: 'button', tooltipText: 'AVE', text: `${roundFnString(info?.ave) || 0}` }
      ];
    }
    return [
      { type: 'audience', role: 'button', tooltipText: t('tooltip', 'AUDIENCE', language), text: `${roundFnString(info?.source?.attendance || info?.attendance) || 0}` },
      { type: 'ave', role: 'button', tooltipText: 'AVE', text: `${roundFnString(info?.ave) || 0}` }
    ];
  }, [info?.media_type]);

  const showViews = info?.media_type === 'internet' || info?.media_type === 'socialnetwork' || info?.media_type === 'agency' || info?.media_type === 'press';

  function isClickable(type) {
    if (type === 'comments') {
      return !!Number(info?.comment_count || 0) || info?.post_type !== 'post';
    }
    if (type === 'reposts') {
      return !!Number(info?.repost_count || 0) || info?.post_type !== 'post';
    }
    return false;
  }

  return (
    <>
      {info?.media_type === 'review' && info?.post_type !== 'comment' && (
      <Tooltip text={t('tooltip', 'RATE', language)}>
        <div className={classes.iconWrap}>
          {Array(5).fill(null).map((_, i) => (
            <Icon
              type={info?.rate > i ? 'kid_star' : 'kid_star_outlined'}
              height={16}
              width={16}
              key={i}
              color={info.rate > i ? colors.grey600 : colors.iconMainColor}
              className={cn(classes.iconText)}
              textClass={cn(classes.iconText)}
              btnType={cn(classes.btnIcon)}
            />
          ))}

        </div>
      </Tooltip>
      )}
      {(info?.media_type === 'socialnetwork' || info?.media_type === 'review' || info?.media_type === 'forum') && (
      <>
        {info?.media_type !== 'review' && (
        <div className={classes.iconWrap}>
          <Icon
            color={colors.grey450}
            type="group"
            role="button"
            tooltipText={t('tooltip', 'POTENTIAL_REACH', language)}
            text={roundFnString(info?.potential_reach) || '0'}
            className={cn(classes.iconText)}
            textClass={cn(classes.iconText)}
            btnType={cn(classes.btnIcon)}
          />
        </div>
        )}
        <div className={classes.iconWrap}>
          <Icon
            color={colors.grey450}
            type="engagement"
            role="button"
            tooltipText={t('tooltip', 'ENGAGEMENT', language)}
            text={roundFnString(info?.engagement) || '0'}
            className={cn(classes.iconText)}
            textClass={cn(classes.iconText)}
            btnType={cn(classes.btnIcon)}
          />

          <div className={classes.pipeChild} />
          <div className={classes.iconGroupWrap}>
            {iconsList.map((icon, i) => ((icon?.type === 'likes' || icon?.type === 'has_likes') && !!topEngagments?.length ? (
              <div className={classes.reactions} key={i}>
                {topEngagments.map(({ image }) => <img key={image} alt={image} src={reactions[image]} />)}
              </div>
            ) : (
              <Icon
                {...icon}
                color={colors.grey450}
                key={i}
                className={cn(classes.iconText, isClickable(icon.type) && classes.isClickable)}
                textClass={cn(classes.iconText)}
                btnType={cn(classes.btnIcon)}
                onClick={icon.handleClick}
              />
            )
            ))}
          </div>
        </div>
      </>
      )}
      {info?.media_type !== 'socialnetwork' && info?.media_type !== 'review' && info?.media_type !== 'forum' && (
        iconsList.map((icon, i) => icon && (
          <div className={classes.iconWrap} key={i}>
            <Icon
              {...icon}
              color={colors.grey450}
              className={cn(classes.iconText)}
              textClass={cn(classes.iconText)}
              btnType={cn(classes.btnIcon)}
            />
          </div>
        ))
      )}
      {showViews && isShowMetric(info, 'views') && (
      <div className={classes.iconDuplicates}>
        <Icon
          type="view"
          role="button"
          tooltipText={t('tooltip', info?.media_type === 'socialnetwork' ? 'VIEW' : 'VIEWING', language)}
          text={roundFnString(info?.media_type === 'socialnetwork' ? info?.views_count : (info?.source?.average_attendance || info?.average_attendance)) || '0'}
          className={cn(classes.iconText)}
          textClass={cn(classes.iconText)}
          btnType={cn(classes.btnIcon)}
        />
      </div>
      )}
      {!['review', 'forum'].includes(info?.media_type) && isShowMetric(info, 'doubles') && (
      <div className={classes.iconDuplicates}>
        <Icon
          type="doubles"
          role="button"
          color={colors.grey450}
          tooltipText={t('tooltip', 'DOUBLES', language)}
          text={roundFnString(info?.duplicates_count) || 0}
          className={cn(classes.iconText)}
          textClass={cn(classes.iconText)}
          btnType={cn(classes.btnIcon)}
        />
      </div>
      )}
      <>
        <TonePopover className={classes.iconTone} onSelect={onSubmit} info={info} language={language} idBind={`article-footer-${info?.id}`} />
        <Icon
          type={info?.processed ? 'processed' : 'unprocessed'}
          role="button"
          tooltipText={t('tooltip', info?.processed ? 'ADD_UNPROCESSED' : 'ADD_PROCESSED', language)}
          className={classes.iconText}
          onClick={() => onSubmit({ action: 'changeProcessed', info, body: { processed: !info?.processed } })}
          btnType={cn(
            classes.btnIcon,
            classes.btnIconCenter,
            info?.processed && classes.btnIcon_svgColorGreen
          )}
        />
      </>
    </>
  );
});

IconsListMedia.propTypes = {
  language: string,
  info: shape({}),
  onSubmit: func
};

export default IconsListMedia;
