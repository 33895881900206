import { useState, useEffect } from 'react';
import { Drawer } from 'antd';
import { Icons, Button, Loader, ErrorBoundarySentry } from 'Components';
import cn from 'classnames';
import t from 'utils/translateFunc';
import { useGetTemplateById, useGetTemplateDashboardCharts, useCreateTemplate, useEditTemplate } from 'storage/queryHooks';
import hasOwn from 'lodash/has';
import { func, number, oneOfType, shape, bool } from 'prop-types';
import { sendAlertsEvent } from 'services/amplitude/events';
import colors from 'styles/_colors.scss';
import classes from './TemplateDrawer.module.scss';
import TimeLine from './components/TimeLineComponent';
import ContentComponent from './components/ContentComponent';
import DisplayComponent from './components/DisplayComponent';
import MainComponent from './components/MainComponent';
import PreviewComponent from './components/PreviewComponent';

const emptyMain = { title: '', document_title: '', logo_url: '', logo: '' };
const emptyContent = { custom_chart: [], dashboard_chart: [] };
const emptyDisplay = {
  separate_socialnetwork: false,
  show_contents: false,
  show_contents_tone: false,
  show_contents_source: false,
  show_duplicates: false,
  group_under_original: false,
  repeating_articles_display: 'display_for_each',
  text_display_type: 'full_text_block',
  articles_group_by: 'keywords__keyword',
  articles_order_by: 'date',
  articles_order_by_desc: false,
  by_created_date: false,
  article_fields: [],
  font_scale: 'scale_m',
  charts_small: false,
  highlight_keywords: false,
  keywords_highlight_color: '000000',
  blocks_order: ['contents_block', 'charts_block', 'articles_block']
};

function TemplateDrawer({ id = null, portalId = null, open = false, dataTemplate = null,
  onFullScreen = () => null, onClose = () => null, onRemove = () => null, onChange = () => null }) {
  const [step, setStep] = useState(1);
  const [errors, setErrors] = useState({});
  const { isLoading: templateLoading } = useGetTemplateById({ id, open });
  const [main, setMain] = useState(emptyMain);
  const [logoFile, setLogoFile] = useState('');
  const [content, setContent] = useState(emptyContent);
  const [display, setDisplay] = useState(emptyDisplay);
  const { data: chartsData } = useGetTemplateDashboardCharts({ enabled: open });
  const { mutateAsync: createTemlate, isSuccess: createSuccess, isLoading: createTemlateLoading } = useCreateTemplate();
  const { mutateAsync: editTemlate, isSuccess: editSuccess, isLoading: editTemlateLoading } = useEditTemplate();
  const amplitudeEventProperties = {
    isDocumentTitle: !!dataTemplate?.document_title,
    isLogo: !!dataTemplate?.logo_url || !!dataTemplate?.logo,
    isCustomChart: !!dataTemplate?.custom_chart?.length,
    isDashboardChart: !!dataTemplate?.dashboard_chart?.length,
    isSeparateSocialnetwork: dataTemplate?.separate_socialnetwork,
    isShowContents: dataTemplate?.show_contents,
    isShowContentsTone: dataTemplate?.show_contents_tone,
    isShowContentsSource: dataTemplate?.show_contents_source,
    isShowDuplicates: dataTemplate?.show_duplicates,
    isGroupUnderOriginal: dataTemplate?.group_under_original,
    textDisplayType: dataTemplate?.text_display_type,
    articlesGroupBy: dataTemplate?.articles_group_by,
    articlesOrderBy: dataTemplate?.articles_order_by,
    isArticlesOrderByDesc: dataTemplate?.articles_order_by_desc,
    byCreatedDate: dataTemplate?.by_created_date,
    repeatingArticlesDisplay: dataTemplate?.repeating_articles_display,
    articleFields: dataTemplate?.article_fields || [],
    fontScale: dataTemplate?.font_scale,
    isChartsSmall: dataTemplate?.charts_small,
    isHighlightKeywords: dataTemplate?.highlight_keywords,
    keywordsHighlightColor: dataTemplate?.keywords_highlight_color,
    blocksOrder: dataTemplate?.blocks_order,
    portal: dataTemplate?.portal
  };

  useEffect(() => {
    if (open && id && dataTemplate) {
      step === 1 && setMain({
        title: dataTemplate?.title,
        document_title: dataTemplate?.document_title,
        logo_url: dataTemplate?.logo_url || ''
      });
      step === 2 && setContent({
        custom_chart: dataTemplate?.custom_chart || [],
        dashboard_chart: dataTemplate?.dashboard_chart || []
      });
      step === 3 && setDisplay({
        id: dataTemplate?.id || null,
        separate_socialnetwork: dataTemplate?.separate_socialnetwork,
        show_contents: dataTemplate?.show_contents,
        show_contents_tone: dataTemplate?.show_contents_tone,
        show_contents_source: dataTemplate?.show_contents_source,
        show_duplicates: dataTemplate?.show_duplicates,
        group_under_original: dataTemplate?.group_under_original,
        text_display_type: dataTemplate?.text_display_type,
        articles_group_by: dataTemplate?.articles_group_by,
        articles_order_by: dataTemplate?.articles_order_by,
        articles_order_by_desc: dataTemplate?.articles_order_by_desc,
        by_created_date: dataTemplate?.by_created_date,
        repeating_articles_display: dataTemplate?.repeating_articles_display,
        article_fields: dataTemplate?.article_fields || [],
        font_scale: dataTemplate?.font_scale,
        charts_small: dataTemplate?.charts_small,
        highlight_keywords: dataTemplate?.highlight_keywords,
        keywords_highlight_color: dataTemplate?.keywords_highlight_color,
        blocks_order: dataTemplate?.blocks_order,
        xls_fields: dataTemplate?.xls_fields
      });
    }
  }, [dataTemplate, id, open, step]);

  useEffect(() => {
    if (open && !id) {
      onChange({ ...emptyMain, ...emptyContent, ...emptyDisplay, portal: portalId });
      setMain(emptyMain); setContent(emptyContent); setDisplay(emptyDisplay); setLogoFile('');
    }
  }, [id, open]);

  useEffect(() => {
    if (editSuccess || createSuccess) {
      onHandleClose();
    }
  }, [createSuccess, editSuccess]);

  function onHandleClose() {
    setStep(1);
    setErrors({});
    setLogoFile('');
    onClose();
  }

  function onSubmit() {
    const body = { ...dataTemplate, logo: logoFile || '' };
    sendAlertsEvent({ category: `${dataTemplate?.id ? 'Edit' : 'Add'} template`, event: 'Clicked_Save_Button', properties: amplitudeEventProperties });
    if (body?.xls_fields === null) delete body.xls_fields;
    dataTemplate?.id ? editTemlate({ body, id: dataTemplate?.id }) : createTemlate(body);
  }

  function onChangeErrors(errs) {
    const errorObj = {
      ...(hasOwn(errs, 'title') ? { title: errs.title } : {}),
      ...(hasOwn(errs, 'document_title') ? { document_title: errs.document_title } : {})
    };
    setErrors({ ...errors, ...errorObj });
  }

  function check1StepFields() {
    const errs = {};
    !main?.title && (errs.title = t('error', 'ERR_REQUIRED'));
    !!main?.title && main?.title.length < 2 && (errs.title = t('error', 'MIN_SYMBOLS'));
    !!main?.title && main?.title.length > 100 && (errs.title = t('error', 'MAX_NAME_LENGTH_100'));
    !!main?.document_title && main?.document_title.length < 2 && (errs.document_title = t('error', 'MIN_SYMBOLS'));
    !!main?.document_title && main?.document_title?.length > 100 && (errs.document_title = t('error', 'MAX_NAME_LENGTH_100'));
    onChangeErrors(errs);
    return !!Object.values(errs).some(Boolean);
  }

  function onChangeStep(value, sendEvent = false) {
    sendEvent && sendAlertsEvent({ category: `${dataTemplate?.id ? 'Edit' : 'Add'} template`, event: 'Clicked_Next_Button' });
    if (step === 1 && check1StepFields()) {
      return;
    }
    step === 1 && onChange(main);
    step === 2 && onChange(content);
    step === 3 && onChange(display);
    setStep(value);
  }

  const stepComponents = {
    1: <MainComponent
      data={main}
      logoFile={logoFile}
      onChangeLogoFile={setLogoFile}
      errors={errors}
      onChangeErrors={onChangeErrors}
      onChange={(value) => { setMain({ ...main, ...value }); }}
    />,
    2: <ContentComponent
      data={content}
      customPages={chartsData?.customPages || []}
      dashboardPages={chartsData?.dashboardPages || []}
      onChange={(value) => {
        setContent({ ...content, ...value });
      }}
    />,
    3: <DisplayComponent
      data={display}
      onChange={(value) => {
        setDisplay({ ...display, ...value });
      }}
    />,
    4: <PreviewComponent data={{ ...dataTemplate, logo: logoFile || '' }} onFullScreen={onFullScreen} />
  };
  return (
    <Drawer
      title={open ? t('reports', !id ? 'CREATE_TEMLATE' : 'EDIT_REPORT_TEMLATE') : false}
      placement="right"
      closable={false}
      width={800}
      onClose={onHandleClose}
      open={!!open}
      destroyOnClose
      styles={{
        header: {
          height: '58px',
          padding: '16px',
          textAlign: 'center'
        },
        content: { backgroundColor: colors.grey100 }
      }}
      rootClassName={classes.templateDrawerWrap}
      extra={(
        <button
          className={classes.exitBtn}
          onClick={onHandleClose}
          type="button"
        >
          <Icons
            icon="Icn_Clear"
            className={cn(classes.icon)}
          />
        </button>
      )}
      footer={(
        <div className={classes.templateFooter}>
          <div className={classes.buttonBox}>
            {id && <Button onClick={onRemove} textButton={t('btn', 'DELETE')} className={classes.deleteBtn} />}
            <Button
              onClick={() => {
                sendAlertsEvent({ category: `${dataTemplate?.id ? 'Edit' : 'Add'} template`, event: 'Clicked_Cancel_Button' });
                onHandleClose();
              }}
              textButton={t('btn', 'CANCEL')}
              className={classes.cancelBtn}
              purpleTransparent32
            />
            <Button onClick={() => { step === 4 ? onSubmit() : onChangeStep(step + 1, true); }} textButton={t('btn', step === 4 ? 'SAVE' : 'FURTHER')} green32 />
          </div>
        </div>
      )}
    >

      <Loader size={65} fetching={templateLoading || createTemlateLoading || editTemlateLoading} color={colors.font35}>
        <ErrorBoundarySentry>
          {!!open && (
          <div className={classes.templateBody}>
            <TimeLine
              step={step}
              onChangeStep={onChangeStep}
              body={amplitudeEventProperties}
            />
            <div className={classes.templateBodyComponent}>
              {stepComponents[step]}
            </div>
          </div>
          )}
        </ErrorBoundarySentry>
      </Loader>

    </Drawer>
  );
}

TemplateDrawer.propTypes = {
  id: oneOfType([number, shape]),
  portalId: oneOfType([number, shape]),
  open: bool,
  dataTemplate: shape({}),
  onFullScreen: func,
  onClose: func,
  onRemove: func,
  onChange: func

};

export default TemplateDrawer;
