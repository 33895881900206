import { string, number, bool, func } from 'prop-types';
import { Icon } from 'Components';
import classes from './ImagesPreview.module.scss';

function ImagesPreview({ imageUrl, imagesNum = 0, width = 160, height = 120, isVideo = false, handleClick = () => null }) {
  return (
    <div onClick={handleClick} className={classes.preview}>
      <div className={classes.imgWrap}>
        <img src={imageUrl} alt="" style={{ maxWidth: `${width}px`, height: `${height}px` }} />
      </div>
      {imagesNum >= 2 && (
        <div className={classes.imgCounter}>
          <div className={classes.imgCounterText}>
            <span>
              +
              {imagesNum - 1}
            </span>
            <div className={classes.imgCounterIcon}>
              <Icon type="photo_camera" width={10} height={9} />
            </div>
          </div>
        </div>
      )}
      {isVideo && (
        <div className={classes.playBtn} style={{ bottom: imagesNum >= 2 ? '96px' : '76px' }}>
          <Icon type="play_button_big" width={36} height={36} />
        </div>
      )}
    </div>
  );
}

ImagesPreview.propTypes = {
  imageUrl: string,
  imagesNum: number,
  width: number,
  height: number,
  isVideo: bool,
  handleClick: func
};

export default ImagesPreview;
