import { string, number } from 'prop-types';
import colors from 'styles/_colors.scss';

export function PhotoCamera({ width = 10, height = 9, color = colors.white, className = '' }) {
  return (
    <svg className={className} width={width || '10'} height={height || '9'} viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 7.25C5.625 7.25 6.15625 7.03125 6.59375 6.59375C7.03125 6.15625 7.25 5.625 7.25 5C7.25 4.375 7.03125 3.84375 6.59375 3.40625C6.15625 2.96875 5.625 2.75 5 2.75C4.375 2.75 3.84375 2.96875 3.40625 3.40625C2.96875 3.84375 2.75 4.375 2.75 5C2.75 5.625 2.96875 6.15625 3.40625 6.59375C3.84375 7.03125 4.375 7.25 5 7.25ZM5 6.25C4.65 6.25 4.35417 6.12917 4.1125 5.8875C3.87083 5.64583 3.75 5.35 3.75 5C3.75 4.65 3.87083 4.35417 4.1125 4.1125C4.35417 3.87083 4.65 3.75 5 3.75C5.35 3.75 5.64583 3.87083 5.8875 4.1125C6.12917 4.35417 6.25 4.65 6.25 5C6.25 5.35 6.12917 5.64583 5.8875 5.8875C5.64583 6.12917 5.35 6.25 5 6.25ZM1 9C0.725 9 0.489583 8.90208 0.29375 8.70625C0.0979167 8.51042 0 8.275 0 8V2C0 1.725 0.0979167 1.48958 0.29375 1.29375C0.489583 1.09792 0.725 1 1 1H2.575L3.2 0.325C3.29167 0.225 3.40208 0.145833 3.53125 0.0875C3.66042 0.0291667 3.79583 0 3.9375 0H6.0625C6.20417 0 6.33958 0.0291667 6.46875 0.0875C6.59792 0.145833 6.70833 0.225 6.8 0.325L7.425 1H9C9.275 1 9.51042 1.09792 9.70625 1.29375C9.90208 1.48958 10 1.725 10 2V8C10 8.275 9.90208 8.51042 9.70625 8.70625C9.51042 8.90208 9.275 9 9 9H1Z"
        fill={color || colors.white}
      />
    </svg>
  );
}

PhotoCamera.propTypes = {
  width: number,
  height: number,
  color: string,
  className: string
};
